import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { getProductOrder } from "../actions/orders";

export const TrackOrder = ({
  match,
  getProductOrder,
  product_order: { product_order },
}) => {
  const [orderno, setOrderno] = useState("");
  const checkOrder = () => {
    if (orderno && orderno !== "") {
      getProductOrder(orderno);
    }
  };
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />
        <div className="all-product-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div style={{ background: "#fff" }}>
                  <div style={{ padding: "20px 20%" }}>
                    <h3> Track Order No </h3>
                    <div
                      style={{
                        display: "flex",
                        width: "50%",
                        padding: "10px 0px",
                      }}
                    >
                      <div>
                        <input
                          className="form-control"
                          value={orderno}
                          onChange={(e) => setOrderno(e.target.value)}
                        />
                      </div>
                      <div>
                        <button
                          onClick={() => checkOrder()}
                          className="collapsed chck-btn hover-btn"
                        >
                          Check Order No
                        </button>
                      </div>
                    </div>
                    {product_order && (
                      <div className="heading_s1">
                        <h3>Order Details</h3>
                        <div>Total Amount: ₹{product_order.total_amount}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ product_order: state.product_order });

const mapDispatchToProps = { getProductOrder };

export default connect(mapStateToProps, mapDispatchToProps)(TrackOrder);
