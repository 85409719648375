import api from "../utils/api";
import {
  GET_SEARCHS,
  TOTAL_SEARCHS,
  ADD_SEARCH,
  GET_SEARCH,
  SEARCHS_ERROR,
  RESET_SEARCH,
} from "./types";

// Get posts
export const countSearch = (searchParams) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_SEARCH,
    });
    let query = "/products/count";
    let newQ = ``;
    searchParams.map((item, index) => {
      if (index === 0) {
        newQ += `?${item.label}=${item.value}`;
      } else {
        newQ += `&${item.label}=${item.value}`;
      }
    });
    console.log("Query", newQ);
    query = query + newQ;
    let res = await api.get(query);
    dispatch({
      type: TOTAL_SEARCHS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCHS_ERROR,
      payload: err,
    });
  }
};

// Get posts
export const resetSearch = (page, searchParams) => async (dispatch) => {
  dispatch({
    type: RESET_SEARCH,
  });
};
export const getSearchs = (page, searchParams) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_SEARCH,
    });
    let start = 0;
    let limit = 10;
    if (page) {
      start = (page - 1) * 10;
    }
    let query =
      "/products?_limit=" +
      limit +
      "&_start=" +
      start +
      "&_sort=createdAt:DESC";
    if (searchParams) {
      let newQ = ``;
      searchParams.map((item) => {
        newQ += `&${item.label}=${item.value}`;
      });
      console.log("Query", newQ);
      query = query + newQ;
    }

    const res = await api.get(query);

    dispatch({
      type: GET_SEARCHS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCHS_ERROR,
      payload: err,
    });
  }
};
// Get posts
export const getSearch = (id) => async (dispatch) => {
  try {
    const res = await api.get("/products/" + id);

    dispatch({
      type: GET_SEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCHS_ERROR,
      payload: err,
    });
  }
};

export const addSearch = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SEARCH,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SEARCHS_ERROR,
      payload: err,
    });
  }
};
export const editSearch = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/products/" + id, data);

    dispatch(getSearch(id));
  } catch (err) {
    dispatch({
      type: SEARCHS_ERROR,
      payload: err,
    });
  }
};
