import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  addToCartAction,
  decreaseQuantity,
  getCart,
  updateCart,
  removeFromCartAction,
} from "../../actions/carts";
import { URI } from "../../constants/constants";

const CartModal = ({
  showCart,
  hideCart,
  removeFromCartAction,
  addToCartAction,
  updateCart,
  decreaseQuantity,
  cart: { cart },
}) => {
  const [totalAmount, setTotalAmount] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(null);
  useEffect(() => {
    let total = 0;
    if (cart) {
      cart.map((item) => {
        total = total + item.sale_price * item.quantity;
      });
    }

    setTotalAmount(total);
  }, [cart, totalAmount]);
  useEffect(() => {
    let total = 0;
    if (cart) {
      cart.map((item) => {
        total = total + item.regular_price * item.quantity;
      });
    }

    setTotalDiscount(total);
  }, [cart, totalDiscount]);
  const decreaseQuantityFromCart = (item) => {
    decreaseQuantity(item.slug);
  };
  const addToCart = (item) => {
    addToCartAction({
      name: item.name,
      featured_image: item.featured_image && item.featured_image.url,
      sale_price: item.sale_price,
      slug: item.slug,
      unit: item.units,
      regular_price: item.regular_price,
    });
  };
  return (
    <div>
      <div
        className="bs-canvas bs-canvas-right position-fixed bg-cart h-100"
        style={{
          marginRight: showCart ? "0px" : "-400px",
          boxShadow:
            "0 3.4px 2.7px -30px rgb(0 0 0 / 6%), 0 8.2px 8.9px -30px rgb(0 0 0 / 7%), 0 25px 40px -30px rgb(0 0 0 / 20%)",
        }}
      >
        <div className="bs-canvas-header side-cart-header p-3">
          <div className="d-inline-block main-cart-title">
            My Cart <span>({cart && cart.length} Items)</span>
          </div>
          <button
            type="button"
            className="bs-canvas-close close"
            aria-label="Close"
            onClick={() => hideCart()}
          >
            <i className="uil uil-multiply" />
          </button>
        </div>
        <div className="bs-canvas-body">
          <div className="side-cart-items">
            {cart &&
              cart.map((item, index) => {
                return (
                  <div className="cart-item">
                    <div className="cart-product-img">
                      {item.featured_image ? (
                        <img src={`${URI}${item.featured_image}`} />
                      ) : (
                        <img src="/images/product-placeholder.jpg" />
                      )}
                    </div>
                    <div className="cart-text">
                      <h4> {item.name} </h4>
                      <div className="cart-radio">
                        <ul className="">
                          <li>
                            <label style={{ color: "#8f91ac" }}>
                              {" "}
                              {item.unit}{" "}
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="qty-group">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                            onClick={() => decreaseQuantityFromCart(item)}
                          />
                          <input
                            type="number"
                            step={1}
                            name="quantity"
                            disabled
                            value={item.quantity}
                            className="input-text qty text"
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            onClick={() => addToCart(item)}
                            className="plus plus-btn"
                          />
                        </div>
                        <div className="cart-item-price">
                          ₹{item.sale_price}
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => removeFromCartAction(item.slug)}
                        className="cart-close-btn"
                      >
                        <i className="uil uil-multiply" />
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="bs-canvas-footer">
          {totalAmount < 599 ? (
            <div style={{ color: "red", padding: "10px 20px" }}>
              Add Items of ₹{599 - totalAmount} more for free delivery
            </div>
          ) : (
            <div style={{ color: "red", padding: "10px 20px" }}>
              You are eligible for free home delivery
            </div>
          )}
          <div className="main-total-cart">
            <h2>Sub Total</h2>
            <span>₹{totalAmount}</span>
          </div>
          <div className="main-total-cart">
            <h2>Delivery Charges</h2>
            <span>₹{totalAmount < 599 ? 50 : 0}</span>
          </div>
          <div className="main-total-cart">
            <h2>Total</h2>
            <span>₹{totalAmount + (totalAmount < 599 ? 50 : 0)}</span>
          </div>
          <div className="checkout-cart">
            <Link to="/checkout" className="cart-checkout-btn hover-btn">
              Proceed to Checkout
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ cart: state.cart });

const mapDispatchToProps = {
  removeFromCartAction,
  addToCartAction,
  updateCart,
  decreaseQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartModal);
