import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Banner from "../components/homepage/Banner";
import BestValueOffers from "../components/homepage/BestValueOffers";
import CategoryOffers from "../components/homepage/CategoryOffers";
import FeaturedProduct from "../components/homepage/FeaturedProduct";
import Offers from "../components/homepage/Offers";
import RecentProducts from "../components/homepage/RecentProducts";
import ShopByCategory from "../components/homepage/ShopByCategory";
import FooterMenu from "../components/layout/FooterMenu";

import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { URI } from "../constants/constants";

const Home = ({ match, category: { category } }) => {
  return (
    <div>
      <div>
        <Header props={match} />

        <div className="wrapper">
          <ShopByCategory show={true} />
          <Banner />
          <FeaturedProduct match={match} />
          {/* <BestValueOffers /> */}
          {/* <Offers /> */}
          {/* Category */}
          {category &&
            category.map((item) => {
              return (
                <div className="container">
                  <div className="row " style={{ padding: "30px 0px" }}>
                    <div className="col-md-12">
                      <div class="main-title-left">
                        <span>Shop By Category</span>
                        <h2>{item.name}</h2>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ background: "#fff", padding: "20px 0px" }}
                  >
                    {item.subcategories &&
                      item.subcategories.map((cat) => {
                        return (
                          <div
                            className={
                              window.innerWidth > 745 ? "col-md-2" : "col-4"
                            }
                          >
                            <Link to={`/category/${item.slug}/${cat.slug}`}>
                              <div
                                style={{
                                  background: `url(${URI}${item.image})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "10px",
                                  backgroundPosition: "center",
                                }}
                              >
                                <div
                                  style={{
                                    background: "rgba(215, 255, 193, 0.9)",
                                    fontSize: "18px",
                                    color: "#08192c",
                                    fontWeight: "500",
                                    padding: "30px 0px",
                                    color: "#333",
                                    fontSize:
                                      window.innerWidth > 745 ? "16px" : "12px",
                                    marginBottom: "10px",
                                    border: "1px solid #f1f1f1",
                                    height: "100px",
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {cat.name}
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}

          {/* <CategoryOffers /> */}
          {/* <RecentProducts /> */}
        </div>
        <Footer />
      </div>
      {window.innerWidth < 745 && <FooterMenu />}
    </div>
  );
};

const mapStateToProps = (state) => ({ category: state.category });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
