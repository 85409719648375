import api from "../utils/api";
import {
  GET_BANNERS,
  TOTAL_BANNERS,
  ADD_BANNER,
  GET_BANNER,
  BANNERS_ERROR,
} from "./types";

// Get posts
export const countBanner = (searchParams) => async (dispatch) => {
  try {
    let query = "/banner/count";
    let newQ = ``;
    searchParams.map((item, index) => {
      if (index === 0) {
        newQ += `?${item.label}=${item.value}`;
      } else {
        newQ += `&${item.label}=${item.value}`;
      }
    });
    console.log("Query", newQ);
    query = query + newQ;
    let res = await api.get(query);
    dispatch({
      type: TOTAL_BANNERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BANNERS_ERROR,
      payload: err,
    });
  }
};

// Get posts
export const getBanners = (page, searchParams) => async (dispatch) => {
  try {
    let start = 0;
    let limit = 10;
    if (page) {
      start = (page - 1) * 10;
    }
    let query =
      "/banner?_limit=" + limit + "&_start=" + start + "&_sort=createdAt:DESC";
    if (searchParams) {
      let newQ = ``;
      searchParams.map((item) => {
        newQ += `&${item.label}=${item.value}`;
      });
      console.log("Query", newQ);
      query = query + newQ;
    }

    const res = await api.get(query);

    dispatch({
      type: GET_BANNERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BANNERS_ERROR,
      payload: err,
    });
  }
};
// Get posts
export const getBanner = () => async (dispatch) => {
  try {
    const res = await api.get("/banner");

    dispatch({
      type: GET_BANNER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BANNERS_ERROR,
      payload: err,
    });
  }
};

export const addBanner = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/banner`, data);

    dispatch({
      type: ADD_BANNER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BANNERS_ERROR,
      payload: err,
    });
  }
};
export const editBanner = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/banner/" + id, data);

    dispatch(getBanner(id));
  } catch (err) {
    dispatch({
      type: BANNERS_ERROR,
      payload: err,
    });
  }
};
