import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import category from "./category";
import product from "./product";
import cart from "./cart";
import search from "./search";
import product_order from "./order";
import banner from "./banner";

export default combineReducers({
  auth,
  alert,
  category,
  product,
  cart,
  search,
  product_order,
  banner,
});
