import {
  GET_PRODUCT_ORDERS,
  TOTAL_PRODUCT_ORDERS,
  ADD_PRODUCT_ORDER,
  EDIT_PRODUCT_ORDER,
  GET_PRODUCT_ORDER,
  PRODUCT_ORDERS_ERROR,
} from "../actions/types";

const initialState = {
  product_orders: null,
  product_order: null,
  total_product_orders: 0,
  loading: true,
  error: {},
  product_order_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCT_ORDERS:
      return {
        ...state,
        product_orders: payload,
        loading: false,
        error: {},
      };

    case TOTAL_PRODUCT_ORDERS:
      return {
        ...state,
        total_product_orders: payload,
        loading: false,
        error: {},
      };

    case ADD_PRODUCT_ORDER:
      return {
        ...state,
        product_order_message: payload,
        loading: false,
        error: {},
      };
    case GET_PRODUCT_ORDER:
      return {
        ...state,
        product_order: payload,
        loading: false,
        error: {},
      };
    case EDIT_PRODUCT_ORDER:
      return {
        ...state,
        product_order_message: payload,
        loading: false,
        error: {},
      };

    case PRODUCT_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
