import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCategory } from "../../actions/categories";
import { URI } from "../../constants/constants";

const CategoryList = ({ show, getCategory, category: { category } }) => {
  useEffect(() => {
    getCategory();
  }, []);
  return (
    <div>
      {show && (
        <div className="category-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">Explore Categories</div>
                <div className="row">
                  {category &&
                    category.map((item) => {
                      return (
                        <div className="col-4">
                          <div className="single-category">
                            <Link to={`/category/${item.slug}`}>
                              <div className="cat-image">
                                <img
                                  src={
                                    item.image
                                      ? `${URI}${item.image}`
                                      : "images/category/icon-1.svg"
                                  }
                                />
                              </div>
                              <div className="cat-name">{item.name}</div>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ category: state.category });

const mapDispatchToProps = { getCategory };

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
