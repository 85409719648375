import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ShopByCategory from "../components/homepage/ShopByCategory";
import FeaturedProducts from "../components/single-product/FeaturedProducts";
import Gallery from "../components/single-product/Gallery";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { getProduct } from "../actions/products";
import renderHtml from "react-render-html";
import { Link } from "react-router-dom";
import CartBtn from "../components/cart-button/CartBtn";
const SingleProduct = ({
  match,
  getProduct,
  product: { loading, product },
  category: { category },
}) => {
  useEffect(() => {
    getProduct(match.params.id);
  }, [match]);
  const [activeCat, setActiveCat] = useState(null);
  const [activeSubCat, setActiveSubCat] = useState(null);
  const [activeMainCat, setActiveMainCat] = useState(null);
  useEffect(() => {
    if (product) {
      const filteredData =
        category &&
        category.filter((item) => item.slug === product.category_slug);
      console.log(filteredData);
      if (filteredData && filteredData.length > 0) {
        setActiveCat(filteredData[0]);
      }
    }
  }, [product, category]);
  useEffect(() => {
    if (activeCat && product) {
      const newData = activeCat.subcategories.filter(
        (item) => item.slug === product.subcategory_slug
      );
      if (newData && newData.length > 0) {
        setActiveSubCat(newData[0]);
      }
    }
  }, [product, activeCat]);
  useEffect(() => {
    if (activeSubCat && product) {
      const newData = activeSubCat.subcategory.filter(
        (item) => item.slug === product.main_category_slug
      );
      if (newData && newData.length > 0) {
        setActiveMainCat(newData[0]);
      }
    }
  }, [product, activeSubCat]);
  const openFacebookURL = () => {
    if (window) {
      console.log(window.location.pathname);
      const url = `https://saversdelights.com${window.location.pathname}`;

      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`,
        "facebook-share-dialog",
        "width=626,height=436"
      );
    }
  };
  const openWhatsappURL = () => {
    if (window) {
      console.log(window.location.pathname);
      //   const url = `https://vstore-site.herokuapp.com${window.location.pathname}`;

      //   window.location.href = `https://api.whatsapp.com/send?text=https://savethequest.org${window.location.pathname}`;
      window.open(
        `https://api.whatsapp.com/send?text=https://saversdelights.com${window.location.pathname}`,
        "facebook-share-dialog",
        "width=626,height=436"
      );
    }
  };
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />

        <div className="all-product-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="product-dt-view">
                  {!loading ? (
                    product && (
                      <div className="row">
                        <Gallery product={product} />

                        <div className="col-lg-7 col-md-7">
                          <div className="breadcrumb-1">
                            <ul style={{ display: "flex" }}>
                              <li>
                                <Link to="/"> Home </Link>
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-angle-right"></i>{" "}
                              </li>
                              <li>
                                <Link
                                  to={`/category/${
                                    activeCat && activeCat.slug
                                  }`}
                                >
                                  {" "}
                                  {activeCat && activeCat.name}{" "}
                                </Link>
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-angle-right"></i>{" "}
                              </li>
                              <li>
                                <Link
                                  to={`/category/${
                                    activeCat && activeCat.slug
                                  }/${activeSubCat && activeSubCat.slug}`}
                                >
                                  {" "}
                                  {activeSubCat && activeSubCat.name}{" "}
                                </Link>
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-angle-right"></i>{" "}
                              </li>
                              <li>
                                <Link
                                  to={`/category/${
                                    activeCat && activeCat.slug
                                  }/${activeSubCat && activeSubCat.slug}/${
                                    activeMainCat && activeMainCat.slug
                                  }`}
                                >
                                  {activeMainCat && activeMainCat.name}
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="product-dt-right">
                            <h2> {product && product.name}</h2>
                            <div className="no-stock">
                              <p className="stock-qty">
                                {product && product.in_stock
                                  ? "Available"
                                  : "Not Available"}
                                <span>
                                  (
                                  {product && product.in_stock
                                    ? "In Stock"
                                    : "Out of Stock"}
                                  )
                                </span>
                              </p>
                            </div>
                            <div className="product-radio">
                              <ul className="">
                                <li>
                                  <label
                                    htmlFor="p1"
                                    style={{ color: "#8f91ac" }}
                                  >
                                    {product.units}
                                  </label>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <div className="regular_price">
                                {" "}
                                Product MRP:₹{product.regular_price}
                              </div>
                              <div className="sale_price">
                                Selling price:₹{product.sale_price}
                              </div>
                              <div className="inclusive-tax">
                                (Inclusive of all taxes)
                              </div>
                            </div>
                            <div className="add-to-cart-single">
                              <CartBtn item={product} />
                            </div>
                            <div
                              className="product_share"
                              style={{ borderBottom: "1px solid #f1f1f1" }}
                            >
                              <span>Share:</span>
                              <ul
                                className="social_icons"
                                style={{ display: "flex" }}
                              >
                                <li>
                                  <a
                                    onClick={() => openFacebookURL()}
                                    style={{
                                      fontSize: "28px",
                                      color: "#3b5998",
                                    }}
                                  >
                                    <i className="uil uil-facebook" />
                                  </a>
                                </li>

                                <li>
                                  <a
                                    onClick={() => openWhatsappURL()}
                                    style={{
                                      fontSize: "28px",
                                      color: "#075E54",
                                    }}
                                  >
                                    <i className="uil uil-whatsapp" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="pdp-details">
                              <ul>
                                <li>
                                  <div className="pdp-group-dt">
                                    <div className="pdp-icon">
                                      <i className="uil uil-usd-circle" />
                                    </div>
                                    <div className="pdp-text-dt">
                                      <span>Genuine Products </span>
                                      <p>We provide 100% Genuine Products</p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="pdp-group-dt">
                                    <div className="pdp-icon">
                                      <i className="uil uil-cloud-redo" />
                                    </div>
                                    <div className="pdp-text-dt">
                                      <span>Free Delivery</span>
                                      <p>
                                        We offer free delivery to our customers
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    <img
                      src="/images/preloader.gif"
                      style={{ height: "50px", width: "auto" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="pdpt-bg">
                  <div className="pdpt-title">
                    <h4>Product Details</h4>
                  </div>
                  <div className="pdpt-body scrollstyle_4">
                    <div className="pdct-dts-1">
                      <div className="pdct-dt-step">
                        <h4>Description</h4>
                        {product &&
                          product.description &&
                          renderHtml(product.description)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
  category: state.category,
});

const mapDispatchToProps = { getProduct };

export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);
