import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { URI } from "../constants/constants";
import CartBtn from "./cart-button/CartBtn";

const ProductCard = ({ item }) => {
  return (
    <div>
      <div className="product-item mb-30">
        <Link to={`/product/${item._id}`} className="product-img">
          <img
            src={
              item.pics && item.pics.length > 0
                ? `${URI}${item.pics[0]}`
                : "/images/product-placeholder.jpg"
            }
          />
          <div className="product-absolute-options">
            {parseInt(item.discount) > 0 && (
              <span className="offer-badge-1">
                {parseInt(item.discount)}% off
              </span>
            )}
          </div>
        </Link>
        <div className="product-text-dt">
          <h4>{item.name}</h4>
          <p>{item.units}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="product-price">
              <div>₹{item.sale_price}</div>
              {item.regular_price > item.sale_price && (
                <div>
                  <span> ₹{item.regular_price} </span>
                </div>
              )}
            </div>
            <div>
              <CartBtn item={item} />
            </div>
            {/* <div className="qty-cart">
                                    <div className="quantity buttons_added">
                                      <input
                                        type="button"
                                        defaultValue="-"
                                        className="minus minus-btn"
                                      />
                                      <input
                                        type="number"
                                        step={1}
                                        name="quantity"
                                        defaultValue={1}
                                        className="input-text qty text"
                                      />
                                      <input
                                        type="button"
                                        defaultValue="+"
                                        className="plus plus-btn"
                                      />
                                    </div>
                                  </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
