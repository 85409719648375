import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  addToCartAction,
  decreaseQuantity,
  getCart,
} from "../../actions/carts";

const CartBtn = ({
  item,
  getCart,
  decreaseQuantity,
  addToCartAction,
  cart: { cart },
}) => {
  const [cartValue, setCartValue] = useState(null);
  useEffect(() => {
    getCart();
  }, []);
  useEffect(() => {
    if (cart) {
      const filterCartItem = cart.filter(
        (cartItem) => cartItem.slug === item._id
      );
      if (filterCartItem.length > 0) {
        setCartValue(filterCartItem[0]);
      }
    }
  }, [cart, setCartValue]);
  const decreaseQuantityFromCart = () => {
    decreaseQuantity(item._id);
  };
  const addToCart = () => {
    addToCartAction({
      name: item.name,
      featured_image: item.pics && item.pics.length > 0 && item.pics[0],
      sale_price: item.sale_price,
      slug: item._id,
      unit: item.units,
      regular_price: item.regular_price,
    });
  };
  return (
    <div>
      <div className="cart_extra">
        {cart &&
        cart.filter((cartItem) => cartItem.slug === item._id).length > 0 ? (
          <div className="cart-product-quantity">
            <div className="quantity">
              <button
                className="minus"
                className="add-to-cart"
                onClick={() => decreaseQuantityFromCart()}
                type="button"
              >
                -
              </button>
              <sapn style={{ padding: "10px 5px" }}>
                {
                  cart.filter((cartItem) => cartItem.slug === item._id)[0]
                    .quantity
                }
              </sapn>
              <button
                className="add-to-cart"
                onClick={() => addToCart()}
                type="button"
              >
                +
              </button>
            </div>
          </div>
        ) : (
          <div>
            <button
              className="add-to-cart"
              onClick={() => addToCart()}
              type="button"
            >
              <i className="icon-basket-loaded" /> Add to cart
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  getCart,
  addToCartAction,
  decreaseQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartBtn);
