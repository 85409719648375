import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { URI } from "../../constants/constants";
import CategoryList from "../layout/CategoryList";

const ShopByCategory = ({ show, category: { category } }) => {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <div>
      {window.innerWidth > 745 ? (
        <div className="section145">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <div className="owl-carousel cate-slider owl-theme"> */}
                {category && (
                  <Slider {...settings}>
                    {category &&
                      category.map((item) => {
                        return (
                          <div className="item">
                            <Link
                              to={`/category/${item.slug}`}
                              className="category-item"
                            >
                              <div className="category-wrapper">
                                <div className="cate-img">
                                  <img
                                    src={
                                      item.image
                                        ? `${URI}${item.image}`
                                        : "images/category/icon-1.svg"
                                    }
                                  />
                                </div>
                                <h4>{item.name}</h4>
                              </div>
                            </Link>
                          </div>
                        );
                      })}

                    {/* </div> */}
                  </Slider>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CategoryList show={show} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ category: state.category });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShopByCategory);
