import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getBanner } from "../../actions/banners";
import { URI } from "../../constants/constants";

const Banner = ({ getBanner, banner: { banner } }) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "60px",
    slidesToScroll: 1,
  };
  useEffect(() => {
    getBanner();
  }, []);
  return (
    <div>
      <div className="main-banner-slider">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {banner && (
                <Slider {...settings}>
                  {/* <div className="owl-carousel offers-banner owl-theme"> */}
                  {banner &&
                    banner.homepage_banners &&
                    banner.homepage_banners.map((item) => {
                      return (
                        <div className="item">
                          <Link to={item.link}>
                            {" "}
                            <img
                              src={`${URI}${item.image}`}
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "5px solid #fff",
                              }}
                            />{" "}
                          </Link>
                        </div>
                      );
                    })}

                  {/* </div> */}
                </Slider>
              )}
              <p
                style={{
                  color: "red",
                  background: "#fff",
                  padding: "10px 20px",
                  fontSize: window.innerWidth > 745 ? "16px" : "12px",
                }}
              >
                Please note Unconfirmed Cash on Delivery orders will not be
                accepted. To Comfirm COD orders please send YES on whatsapp
                Helpline +91 93341 41502 before 11AM
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ banner: state.banner });

const mapDispatchToProps = { getBanner };

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
