import React, { useEffect } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { getProducts } from "../../actions/products";
import PorductCardMobile from "../PorductCardMobile";
import ProductCard from "../ProductCard";

const FeaturedProduct = ({ match, getProducts, product: { products } }) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    centerPadding: "60px",
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <div>
      <div className="section145">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-title-tt">
                <div className="main-title-left">
                  <span>For You</span>
                  {window.innerWidth > 745 ? (
                    <h2>Top Featured Products</h2>
                  ) : (
                    <div className="title"> Top Featured Products </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {/* <div className="owl-carousel featured-slider owl-theme"> */}
              <div>
                {products && (
                  <Slider {...settings}>
                    {products &&
                      products.map((item) => {
                        return (
                          <div className="item">
                            {window.innerWidth > 745 ? (
                              <ProductCard item={item} />
                            ) : (
                              <PorductCardMobile item={item} />
                            )}
                          </div>
                        );
                      })}
                  </Slider>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ product: state.product });

const mapDispatchToProps = { getProducts };

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedProduct);
