import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Footer = ({ category: { category } }) => {
  return (
    <div>
      <footer className="footer">
        <div className="footer-first-row">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <ul className="call-email-alt">
                  <li>
                    <a href="#" className="callemail">
                      FSSAI License Number: 10421000000496
                    </a>
                  </li>
                  <li>
                    <a href="#" className="callemail">
                      <i className="uil uil-dialpad-alt" />
                      +91 93341 41502
                    </a>
                  </li>
                  <li>
                    <a href="#" className="callemail">
                      <i className="uil uil-envelope-alt" />
                      <span>info@saversdelights.com</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="social-links-footer">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-google-plus-g" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-second-row">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="second-row-item">
                  <h4>Categories</h4>
                  <ul>
                    {category &&
                      category.map((item) => {
                        return (
                          <li>
                            <Link to={`/category/${item.slug}`}>
                              {item.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="second-row-item">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <Link to="/about">About US</Link>
                    </li>
                    <li>
                      <Link to="/track-order">Track Order</Link>
                    </li>
                    <li>
                      <Link to="/search">Search</Link>
                    </li>

                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="second-row-item">
                  <h4>Imp Links</h4>
                  <ul>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/cancellation-policy">Cancellation Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/shipping-policy">Shipping Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="second-row-item-app">
                  <h4>Download App</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          className="download-btn"
                          src="/images/download-1.svg"
                          alt
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="second-row-item-payment">
                  <h4>Payment Method</h4>
                  <div className="footer-payments">
                    <ul id="paypal-gateway" className="financial-institutes">
                      <li className="financial-institutes__logo">
                        <img
                          alt="Visa"
                          title="Visa"
                          src="/images/footer-icons/pyicon-6.svg"
                        />
                      </li>
                      <li className="financial-institutes__logo">
                        <img
                          alt="Visa"
                          title="Visa"
                          src="/images/footer-icons/pyicon-1.svg"
                        />
                      </li>
                      <li className="financial-institutes__logo">
                        <img
                          alt="MasterCard"
                          title="MasterCard"
                          src="/images/footer-icons/pyicon-2.svg"
                        />
                      </li>
                      <li className="financial-institutes__logo">
                        <img
                          alt="American Express"
                          title="American Express"
                          src="/images/footer-icons/pyicon-3.svg"
                        />
                      </li>
                      <li className="financial-institutes__logo">
                        <img
                          alt="Discover"
                          title="Discover"
                          src="/images/footer-icons/pyicon-4.svg"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-last-row">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-text">
                  <i className="uil uil-copyright" />
                  Copyright {new Date().getFullYear()}
                  <b> Savers Delight</b> . All rights reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => ({ category: state.category });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
