export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const ORDER_PLACED = "ORDER_PLACED";
// Volunteer

export const GET_CATEGORY = "GET_CATEGORY";
export const CATEGORYS_ERROR = "CATEGORYS_ERROR";

// Product
export const GET_PRODUCTS = "GET_PRODUCTS";
export const TOTAL_PRODUCTS = "TOTAL_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const GET_PRODUCT = "GET_PRODUCT";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
export const RESET_PRODUCT = "RESET_PRODUCT";

// Cart
export const GET_CART = "GET_CART";
export const CARTS_ERROR = "CARTS_ERROR";
// Search
export const GET_SEARCHS = "GET_SEARCHS";
export const TOTAL_SEARCHS = "TOTAL_SEARCHS";
export const ADD_SEARCH = "ADD_SEARCH";
export const EDIT_SEARCH = "EDIT_SEARCH";
export const GET_SEARCH = "GET_SEARCH";
export const SEARCHS_ERROR = "SEARCHS_ERROR";
export const RESET_SEARCH = "RESET_SEARCH";
// Order
export const GET_PRODUCT_ORDERS = "GET_PRODUCT_ORDERS";
export const TOTAL_PRODUCT_ORDERS = "TOTAL_PRODUCT_ORDERS";
export const ADD_PRODUCT_ORDER = "ADD_PRODUCT_ORDER";
export const EDIT_PRODUCT_ORDER = "EDIT_PRODUCT_ORDER";
export const GET_PRODUCT_ORDER = "GET_PRODUCT_ORDER";
export const PRODUCT_ORDERS_ERROR = "PRODUCT_ORDERS_ERROR";
// Banner
export const GET_BANNERS = "GET_BANNERS";
export const TOTAL_BANNERS = "TOTAL_BANNERS";
export const ADD_BANNER = "ADD_BANNER";
export const EDIT_BANNER = "EDIT_BANNER";
export const GET_BANNER = "GET_BANNER";
export const BANNERS_ERROR = "BANNERS_ERROR";
