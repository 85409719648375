import React from "react";
import { connect } from "react-redux";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

const ShippingPolicy = ({ match }) => {
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />
        <div className="all-product-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div style={{ background: "#fff", padding: "20px 10px" }}>
                  <h2 className="text-center">  Shipping Policy:</h2>
                 
<p>•	Shipping for all orders made through online within our website and all prices are in Indian Rupees inclusive of VAT. </p>
<p>•	Your order will be delivered within 24 Hrs. We process and ship your order in the most cost-efficient way possible so we can pass the savings on to you in the form of free shipping. </p>
<p>•	If you look in Your Orders and see that your items haven’t shipped yet, don’t worry. We may take a little longer than you expect to ship out your order, but we won’t delay shipping if it means we’ll miss the expected delivery date.</p>
<p>•	If you give us an incorrect delivery address/contact number and a parcel is returned to us or undelivered because of that incorrect address we reserve the right to charge additional postage to cover the re-sending of your parcel to the correct address.</p>
<h2 className="text-center">  CANCELLATION BY SITE / CUSTOMER</h2>
<p>•	You as a customer can cancel your order anytime up to the cut-off time of the slot for which you have placed an order by calling our customer service. </p>
<p>•	In such a case we will refund any payments already made by you for the order. If we suspect any fraudulent transaction by any customer or any transaction which defies the terms and conditions of using the website, we at our sole discretion could cancel such orders. We will maintain a negative list of all fraudulent transactions and customers and would deny access to them or cancel any orders placed by them.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingPolicy);
