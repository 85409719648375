import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput, SelectBox } from "../components/Form/Form";
import { URI } from "../constants/constants";
import { createOrder } from "../actions/auth";
import { getCart } from "../actions/carts";

const Checkout = ({ match, createOrder, history, getCart, cart: { cart } }) => {
  const [step, setStep] = useState(1);
  const [checkoutAsGuest, setCheckoutAsGuest] = useState(true);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [address, setAddress] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(null);
  const [paymentOption, setPaymentOption] = useState("CASH_ON_DELIVERY");
  useEffect(() => {
    let total = 0;
    if (cart) {
      cart.map((item) => {
        total = total + item.sale_price * item.quantity;
      });
    }

    setTotalAmount(total);
  }, [cart, totalAmount]);
  useEffect(() => {
    let total = 0;
    if (cart) {
      cart.map((item) => {
        total = total + item.regular_price * item.quantity;
      });
    }

    setTotalDiscount(total);
  }, [cart, totalDiscount]);
  console.log(address);
  const placeOrder = async () => {
    setOrderPlaced(true);

    if (paymentOption === "CASH_ON_DELIVERY") {
      const formData = {
        address: address,
        customer: customer,
        payment_option: paymentOption,
        is_paid: false,
        product: cart,
        total_amount: totalAmount,
        product_total: totalAmount,
        order_date: new Date(),
      };
      await createOrder(formData);

      localStorage.removeItem("CART");
      getCart();
      setOrderPlaced(false);
      history.push("/order-placed");
    }
    if (paymentOption === "RAZORPAY") {
      const order = await axios.get(
        `https://secretleaves.in/razorpay/pay.php?amount=${totalAmount}`
      );
      var options = {
        key: "rzp_live_h7MHCJIwEwBkbO", // Enter the Key ID generated from the Dashboard
        amount: totalAmount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Secret Leaves",
        description: "",
        image: "https://secretleaves.in/assets/images/secret%20leaves.png",
        order_id: order.data, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          const formData = {
            address: address,
            customer: customer,
            payment_option: paymentOption,
            is_paid: true,
            transaction_id: response.razorpay_payment_id,
            product: cart,
            total_amount: totalAmount,
            product_total: totalAmount,
            order_date: new Date(),
          };
          await createOrder(formData);
          localStorage.setItem("CART", []);
          getCart();
          setOrderPlaced(false);
          history.push("/order-placed");
        },
        prefill: {
          name: `${address.first_name} ${address.last_name}`,
          email: auth && auth.user && auth.user.email,
          contact: auth && auth.user && auth.user.phone,
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert("Payment Failed Please try again");
      });
      rzp1.open();
    }
  };
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />

        <div className="all-product-grid" style={{ margin: "5px 0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-7">
                <div
                  id="checkout_wizard"
                  className="checkout accordion left-chck145"
                  style={{
                    background: "#fff",
                    padding: "10px 10px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 2px 0 rgb(0 0 0 / 22%)",
                  }}
                >
                  <h3> Checkout Now </h3>
                  <div className=" bg-gold mt-3 mb-3 main-title-left">
                    <a href="#">
                      {" "}
                      <h2> Step1 : &nbsp;&nbsp; Billing Address </h2>
                    </a>
                  </div>
                  {step !== 3 && (
                    <div className="checkout-form">
                      <Formik
                        initialValues={{
                          first_name: "",
                          last_name: "",
                          phone: "",
                          address_1: "",
                          address_2: "",
                          landmark: "",
                          city: "",
                          state: "",
                          pincode: "",
                        }}
                        validationSchema={Yup.object({
                          first_name: Yup.string().required("Required"),
                          last_name: Yup.string().required("Required"),
                          phone: Yup.string().required("Required"),
                          address_1: Yup.string().required("Required"),
                          city: Yup.string().required("Required"),
                          state: Yup.string().required("Required"),
                          pincode: Yup.string().required("Required"),
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                          console.log("Submitted");
                          setSubmitting(true);
                          const address = {
                            address_1: values.address_1,
                            address_2: values.address_2,
                            landmark: values.landmark,
                            city: values.city,
                            state: values.state,
                            zipcode: values.pincode,
                          };
                          setAddress(address);
                          const customer = {
                            first_name: values.first_name,
                            last_name: values.last_name,
                            phone: values.phone,
                            email: values.email,
                          };
                          // const formData = {
                          //   first_name: values.first_name,
                          //   last_name: values.last_name,
                          //   phone: values.phone,
                          //   address: [address],
                          // };
                          // await updateCustomer(formData, auth.user._id);
                          setCustomer(customer);
                          setStep(3);
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="First Name"
                                    name="first_name"
                                    type="text"
                                    placeholder="Enter First Name"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Last Name"
                                    name="last_name"
                                    type="text"
                                    placeholder="Enter Last Name"
                                  />
                                </div>

                                <div className="col-md-12">
                                  <TextInput
                                    label="Phone"
                                    name="phone"
                                    type="text"
                                    placeholder="Enter Phone"
                                  />
                                </div>

                                <div className="col-md-12">
                                  <TextInput
                                    label="Email"
                                    name="email"
                                    type="text"
                                    placeholder="Enter Email"
                                  />
                                </div>

                                <div className="col-md-12">
                                  <TextInput
                                    label="Address 1"
                                    name="address_1"
                                    type="text"
                                    placeholder="Enter Address 1"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <TextInput
                                    label="Address 2"
                                    name="address_2"
                                    type="text"
                                    placeholder="Enter Address 2"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Landmark"
                                    name="landmark"
                                    type="text"
                                    placeholder="Enter Landmark"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="City"
                                    name="city"
                                    type="text"
                                    placeholder="Enter City"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <SelectBox
                                    label="Pincode"
                                    name="pincode"
                                    placeholder="Enter Pincode"
                                  >
                                    <option value=""> Select Pincode </option>
                                    <option value="800014"> 800014 </option>
                                    <option value="800025"> 800025 </option>
                                  </SelectBox>
                                </div>

                                <div className="col-md-6">
                                  <TextInput
                                    label="State"
                                    name="state"
                                    type="text"
                                    placeholder="Enter State"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <div className=" link-box clearfix mt-3">
                                    <button
                                      type="submit"
                                      className="collapsed chck-btn hover-btn"
                                    >
                                      {formik.isSubmitting
                                        ? "Processing..."
                                        : "Next"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  )}
                  <div className=" bg-gold mt-3 mb-3 main-title-left">
                    <a href="#">
                      {" "}
                      <h2> Step2 : &nbsp;&nbsp; Payment Options </h2>
                    </a>
                  </div>
                  {step === 3 && (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="order-payment">
                          <div className="payment-method">
                            <div className="form-check mb-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                defaultValue="option1"
                                defaultChecked
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setPaymentOption("CASH_ON_DELIVERY");
                                  }
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                                Cash On Deliery
                              </label>
                            </div>

                            <button
                              type="submit"
                              className="collapsed chck-btn hover-btn"
                              onClick={() => placeOrder()}
                            >
                              {orderPlaced ? "Processing" : "Place Order"}{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-5">
                <div className="pdpt-bg mt-0">
                  <div className="pdpt-title">
                    <h4>Order Summary</h4>
                  </div>
                  <div className="right-cart-dt-body">
                    {cart &&
                      cart.map((item) => {
                        return (
                          <div className="cart-item border_radius">
                            <div className="cart-product-img">
                              {item.featured_image ? (
                                <img
                                  src={`${URI}${item.featured_image}`}
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    objectFit: "contain",
                                  }}
                                />
                              ) : (
                                <img
                                  src="/images/product-placeholder.jpg"
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </div>
                            <div className="quantity">
                              <div>
                                {item.name} X {item.quantity}
                              </div>
                            </div>
                            <div className="quantity-with">
                              <h3>₹{item.sale_price * item.quantity} </h3>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="main-total-cart">
                    <h2>Sub Total</h2>
                    <span>₹{totalAmount}</span>
                  </div>
                  <div className="main-total-cart">
                    <h2>Delivery Charges</h2>
                    <span>₹{totalAmount < 599 ? 50 : 0}</span>
                  </div>
                  <div className="main-total-cart">
                    <h2>Total</h2>
                    <span>₹{totalAmount + (totalAmount < 599 ? 50 : 0)}</span>
                  </div>
                  <div className="payment-secure">
                    <i className="uil uil-padlock" />
                    Secure checkout
                  </div>
                </div>

                <div className="checkout-safety-alerts">
                  <p>
                    <i className="uil uil-sync" />
                    100% Replacement Guarantee
                  </p>
                  <p>
                    <i className="uil uil-check-square" />
                    100% Genuine Products
                  </p>
                  <p>
                    <i className="uil uil-shield-check" />
                    Secure Payments
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ cart: state.cart });

const mapDispatchToProps = { createOrder, getCart };

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
