import React from "react";
import { connect } from "react-redux";
import { addSearch } from "../../actions/searches"

const SearchModal = ({addSearch,search:{search}}) => {
  return (
    <div>
      <div
        id="search_model"
        className="header-cate-model main-Savers Delight-model modal show"
        tabIndex={-1}
        role="dialog"
        aria-modal="false"
      >
        <div className="modal-dialog search-ground-area" role="document">
          <div className="category-area-inner">
            <div className="modal-header">
              <button
                type="button"
                className="close btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="uil uil-multiply" />
              </button>
            </div>
            <div className="category-model-content modal-content">
              <div className="search-header">
                <form action="#">
                  <input type="search" placeholder="Search for products..." />
                  <button type="submit">
                    <i className="uil uil-search" />
                  </button>
                </form>
              </div>
              <div className="search-by-cat">
                <a href="#" className="single-cat">
                  <div className="icon">
                    <img src="/images/category/icon-1.svg" alt />
                  </div>
                  <div className="text">Fruits and Vegetables</div>
                </a>
                <a href="#" className="single-cat">
                  <div className="icon">
                    <img src="/images/category/icon-2.svg" alt />
                  </div>
                  <div className="text">Grocery &amp; Staples</div>
                </a>
                <a href="#" className="single-cat">
                  <div className="icon">
                    <img src="/images/category/icon-3.svg" alt />
                  </div>
                  <div className="text">Dairy &amp; Eggs</div>
                </a>
                <a href="#" className="single-cat">
                  <div className="icon">
                    <img src="/images/category/icon-4.svg" alt />
                  </div>
                  <div className="text">Beverages</div>
                </a>
                <a href="#" className="single-cat">
                  <div className="icon">
                    <img src="/images/category/icon-5.svg" alt />
                  </div>
                  <div className="text">Snacks</div>
                </a>
                <a href="#" className="single-cat">
                  <div className="icon">
                    <img src="/images/category/icon-6.svg" alt />
                  </div>
                  <div className="text">Home Care</div>
                </a>
                <a href="#" className="single-cat">
                  <div className="icon">
                    <img src="/images/category/icon-7.svg" alt />
                  </div>
                  <div className="text">Noodles &amp; Sauces</div>
                </a>
                <a href="#" className="single-cat">
                  <div className="icon">
                    <img src="/images/category/icon-8.svg" alt />
                  </div>
                  <div className="text">Personal Care</div>
                </a>
                <a href="#" className="single-cat">
                  <div className="icon">
                    <img src="/images/category/icon-9.svg" alt />
                  </div>
                  <div className="text">Pet Care</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({search:state.search});

const mapDispatchToProps = {addSearch};

export default connect(mapStateToProps, mapDispatchToProps)(SearchModal);
