import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { URI } from "../../constants/constants";

const Gallery = ({ product }) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "60px",
    slidesToScroll: 1,
  };
  return (
    <div className="col-lg-5 col-md-5">
      {/* <div id="sync1" className="owl-carousel owl-theme"> */}
      <Slider {...settings}>
        {product && product.pics ? (
          product.pics.map((item, index) => {
            return (
              <div className="item">
                <img src={`${URI}${item}`} />
              </div>
            );
          })
        ) : (
          <div className="item">
            <img src={`/images/product-placeholder.jpg`} />
          </div>
        )}
      </Slider>
      {/* </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
