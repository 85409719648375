import React from "react";
import { Route, Switch } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import AllCategory from "./Pages/AllCategory";
import CancellationPolicy from "./Pages/CancellationPolicy";
import Cart from "./Pages/Cart";
import CategoryArchive from "./Pages/CategoryArchive";
import Checkout from "./Pages/Checkout";
import ContactUs from "./Pages/ContactUs";
import Home from "./Pages/Home";
import MainCategoryArchive from "./Pages/MainCategoryArchive";
import OrderPlaced from "./Pages/OrderPlaced";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Search from "./Pages/Search";
import SingleProduct from "./Pages/SingleProduct";
import SubCategoryArchive from "./Pages/SubCategoryArchive";
import Terms from "./Pages/Terms";
import TrackOrder from "./Pages/TrackOrder";
import ShippingPolicy from "./Pages/ShippingPolicy";
import BeforeLoginRoutes from "./Routes/BeforeLoginRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/checkout" component={Checkout} />
      <Route exact path="/search" component={Search} />
      <Route exact path="/cancellation-policy" component={CancellationPolicy} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/about" component={AboutUs} />
      <Route exact path="/shipping-policy" component={ShippingPolicy} />
      <Route exact path="/all-category" component={AllCategory} />
      <Route exact path="/cart" component={Cart} />
      <Route exact path="/order-placed" component={OrderPlaced} />
      <Route exact path="/track-order" component={TrackOrder} />
      <Route exact path="/product/:id" component={SingleProduct} />
      <Route exact path="/category/:cat" component={CategoryArchive} />
      <Route
        exact
        path="/category/:cat/:subcat"
        component={SubCategoryArchive}
      />
      <Route
        exact
        path="/category/:cat/:subcat/:maincat"
        component={MainCategoryArchive}
      />
    </Switch>
  );
}

export default Routes;
