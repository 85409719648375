import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const FooterMenu = ({ cart: { cart } }) => {
  return (
    <div>
      {window.innerWidth < 745 && (
        <div className="footer-1">
          <div className="container">
            <div className="row">
              <div className="col-3">
                <div className="footer-menu">
                  <Link to="/">
                    <div className="menu-icon">
                      <i className="fa fa-home" />
                    </div>
                    <div className="menu-title">Home</div>
                  </Link>
                </div>
              </div>
              <div className="col-3">
                <div className="footer-menu">
                  <Link to="/all-category">
                    <div className="menu-icon">
                      <i className="fa fa-filter" />
                    </div>
                    <div className="menu-title">Category</div>
                  </Link>
                </div>
              </div>
              <div className="col-3">
                <div className="footer-menu">
                  <Link to="/cart">
                    <div className="menu-icon">
                      <i className="fa fa-shopping-cart" />
                      <span> {cart && cart.length} </span>
                    </div>
                    <div className="menu-title">Cart</div>
                  </Link>
                </div>
              </div>
              <div className="col-3">
                <div className="footer-menu">
                  <Link to="/search">
                    <div className="menu-icon">
                      <i className="fa fa-search" />
                    </div>
                    <div className="menu-title">Search</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ cart: state.cart });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FooterMenu);
