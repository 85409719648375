import React from "react";
import { connect } from "react-redux";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

const AboutUs = ({ match }) => {
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />
        <div className="all-product-grid">
          <div className="container">
            <div className="row" style={{ background: "#fff" }}>
              <div className="col-md-6">
                <div style={{ padding: "40px 0px" }}>
                  <p>
                    {" "}
                    SAVERS DELIGHT, a perfect combination of department and
                    grocery store.
                  </p>
                  <p>
                    {" "}
                    <strong> Savers Delight </strong> is a reanalyzed
                    neighborhood food & grocery store that delivers a modern
                    shopping experience to its customers. We have an assorted
                    variety of products; starting from food to personal care to
                    home care, state anything and we have it all. So we have
                    something for every basket.{" "}
                  </p>
                  <p>
                    {" "}
                    With more than 1000+ brands under 1 roof, we are able to
                    cater to the needs of your everyday and monthly requirements
                    of grocery. At SAVERS DELIGHT our motive is to provide the
                    customer with an enormous variety, and thus we have National
                    as well as International brands to make your visit to the
                    store extremely fruitful.
                  </p>
                  <p>
                    {" "}
                    We focus to fulfil the requirements of all those who enter
                    the store and wish that they leave the store with bags full
                    of satisfaction and happiness.
                  </p>
                  <p>
                    <strong>
                      WE JUST FOCUS ON WHAT MATTERS: GREAT PRODUCTS + GREAT
                      PRICES = ‘VALUE’
                    </strong>
                  </p>
                  <p>
                    {" "}
                    <strong> OUR HIGHLIGHTS: </strong>
                  </p>
                  <p>
                    {" "}
                    Our motive is to serve our customers in a better and
                    convenient way. Few key features of our business:
                  </p>
                  <p>
                    {" "}
                    1. Express Delivery: To delivery products to customer in
                    just 90 min. at your door step. Whatever your need be, we
                    are just a call away!
                  </p>
                  <p>
                    {" "}
                    2. Free Delivery: We offer free delivery to our customers
                    with a minimum order of Rs. 599.
                  </p>
                  <p>
                    {" "}
                    3. Offers: Several attractive offers that are beneficial to
                    our customers.
                  </p>
                  <p>
                    {" "}
                    4. Quality products: We ensure that we deliver Quality
                    products to our customer.
                  </p>
                  <p>
                    {" "}
                    <strong>
                      {" "}
                      We guarantee on time delivery, and the best quality!{" "}
                    </strong>{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ padding: "40px 0px" }}>
                  <img
                    src="/images/about.svg"
                    style={{ width: "80%", padding: "30px 20px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
