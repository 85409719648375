import React from "react";
import { connect } from "react-redux";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

const ContactUs = ({ match }) => {
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />
        <div className="all-product-grid">
          <div className="container">
            <div className="row" style={{ background: "#fff" }}>
              <div className="col-md-6">
                <div>
                  <div className="panel-body">
                    <h2>Our Address</h2>
                    Near Danish apartment <br /> Samanpura Raja Bazar <br />{" "}
                    Patna 14
                    <br />
                    <br />
                    <div className="color-pink">Phone :+91 93341 41502</div>
                    <div className="color-pink">
                      Email : info@saversdelights.com
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="contact-title">
                  <h2>Submit customer service request</h2>
                  <p>
                    If you have a question about our service or have an issue to
                    report, please send a request and we will get back to you as
                    soon as possible.
                  </p>
                </div>
                <div className="contact-form">
                  <form>
                    <div className="form-group mt-1">
                      <label className="control-label">Full Name*</label>
                      <div className="ui search focus">
                        <div className="ui left icon input swdh11 swdh19">
                          <input
                            className="prompt srch_explore"
                            type="text"
                            name="sendername"
                            id="sendername"
                            required
                            placeholder="Your Full"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-1">
                      <label className="control-label">Email Address*</label>
                      <div className="ui search focus">
                        <div className="ui left icon input swdh11 swdh19">
                          <input
                            className="prompt srch_explore"
                            type="email"
                            name="emailaddress"
                            id="emailaddress"
                            required
                            placeholder="Your Email Address"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-1">
                      <label className="control-label">Subject*</label>
                      <div className="ui search focus">
                        <div className="ui left icon input swdh11 swdh19">
                          <input
                            className="prompt srch_explore"
                            type="text"
                            name="sendersubject"
                            id="sendersubject"
                            required
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-1">
                      <div className="field">
                        <label className="control-label">Message*</label>
                        <textarea
                          rows={2}
                          className="form-control"
                          id="sendermessage"
                          name="sendermessage"
                          required
                          placeholder="Write Message"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <button
                      className="next-btn16 hover-btn mt-3"
                      type="submit"
                      data-btntext-sending="Sending..."
                    >
                      Submit Request
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
