import {
  GET_SEARCHS,
  TOTAL_SEARCHS,
  ADD_SEARCH,
  EDIT_SEARCH,
  GET_SEARCH,
  SEARCHS_ERROR,
  RESET_SEARCH,
} from "../actions/types";

const initialState = {
  searches: null,
  search: null,
  total_searchs: 0,
  loading: true,
  error: {},
  search_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SEARCHS:
      return {
        ...state,
        searches: payload,
        loading: false,
        error: {},
      };
    case RESET_SEARCH:
      return {
        ...state,
        searches: null,
        loading: true,
        error: {},
      };

    case TOTAL_SEARCHS:
      return {
        ...state,
        total_searchs: payload,
        loading: false,
        error: {},
      };

    case ADD_SEARCH:
      return {
        ...state,
        search: payload,
        loading: false,
        error: {},
      };
    case GET_SEARCH:
      return {
        ...state,
        search: payload,
        loading: false,
        error: {},
      };
    case EDIT_SEARCH:
      return {
        ...state,
        search_message: payload,
        loading: false,
        error: {},
      };

    case SEARCHS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
