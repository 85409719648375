import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Header = ({ cart: { cart } }) => {
  return (
    <div>
      <div className="header-1">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="logo">
                <Link to="/">
                  <img
                    src="https://saversdelights.com/images/logo.png"
                    alt="Savers Delights"
                  />
                </Link>
              </div>
            </div>
            <div className="col-6">
              <div className="header-cart">
                <Link to="/cart">
                  {" "}
                  <i className="fa fa-shopping-cart"> </i>{" "}
                  <span> {cart && cart.length} </span>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ cart: state.cart });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
