import React from "react";
import { connect } from "react-redux";

const CategoryModal = (props) => {
  return (
    <div>
      <div
        id="category_model"
        className="header-cate-model main-Savers Delight-model modal fade"
        tabIndex={-1}
        role="dialog"
        aria-modal="false"
      >
        <div className="modal-dialog category-area" role="document">
          <div className="category-area-inner">
            <div className="modal-header">
              <button
                type="button"
                className="close btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="uil uil-multiply" />
              </button>
            </div>
            <div className="category-model-content modal-content">
              <div className="cate-header">
                <h4>Select Category</h4>
              </div>
              <ul className="category-by-cat">
                <li>
                  <a href="#" className="single-cat-item">
                    <div className="icon">
                      <img src="images/category/icon-1.svg" alt />
                    </div>
                    <div className="text">Fruits and Vegetables</div>
                  </a>
                </li>
                <li>
                  <a href="#" className="single-cat-item">
                    <div className="icon">
                      <img src="images/category/icon-2.svg" alt />
                    </div>
                    <div className="text">Grocery &amp; Staples</div>
                  </a>
                </li>
                <li>
                  <a href="#" className="single-cat-item">
                    <div className="icon">
                      <img src="images/category/icon-3.svg" alt />
                    </div>
                    <div className="text">Dairy &amp; Eggs</div>
                  </a>
                </li>
                <li>
                  <a href="#" className="single-cat-item">
                    <div className="icon">
                      <img src="images/category/icon-4.svg" alt />
                    </div>
                    <div className="text">Beverages</div>
                  </a>
                </li>
                <li>
                  <a href="#" className="single-cat-item">
                    <div className="icon">
                      <img src="images/category/icon-5.svg" alt />
                    </div>
                    <div className="text">Snacks</div>
                  </a>
                </li>
                <li>
                  <a href="#" className="single-cat-item">
                    <div className="icon">
                      <img src="images/category/icon-6.svg" alt />
                    </div>
                    <div className="text">Home Care</div>
                  </a>
                </li>
                <li>
                  <a href="#" className="single-cat-item">
                    <div className="icon">
                      <img src="images/category/icon-7.svg" alt />
                    </div>
                    <div className="text">Noodles &amp; Sauces</div>
                  </a>
                </li>
                <li>
                  <a href="#" className="single-cat-item">
                    <div className="icon">
                      <img src="images/category/icon-8.svg" alt />
                    </div>
                    <div className="text">Personal Care</div>
                  </a>
                </li>
                <li>
                  <a href="#" className="single-cat-item">
                    <div className="icon">
                      <img src="images/category/icon-9.svg" alt />
                    </div>
                    <div className="text">Pet Care</div>
                  </a>
                </li>
              </ul>
              <a href="#" className="morecate-btn">
                <i className="uil uil-apps" />
                More Categories
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal);
