import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { getProducts, countProduct } from "../actions/products";
import { URI } from "../constants/constants";
import ProductCard from "../components/ProductCard";
import Pagination from "../components/Pagination";
import PorductCardMobile from "../components/PorductCardMobile";
import Slider from "react-slick";
import FooterMenu from "../components/layout/FooterMenu";

export const SubCategoryArchive = ({
  match,
  getProducts,
  countProduct,
  product: { products, loading, total_products },
  category: { category },
}) => {
  const [activeCat, setActiveCat] = useState(null);
  const [activeSubCat, setActiveSubCat] = useState(null);
  const [toggleCat, setToggleCat] = useState(null);
  useEffect(() => {
    console.log(match.params);
    const filteredData =
      category && category.filter((item) => item.slug === match.params.cat);
    console.log(filteredData);
    if (filteredData && filteredData.length > 0) {
      setActiveCat(filteredData[0]);
    }
  }, [match.params.cat, category]);
  useEffect(() => {
    if (activeCat) {
      const newData = activeCat.subcategories.filter(
        (item) => item.slug === match.params.subcat
      );
      if (newData && newData.length > 0) {
        setActiveSubCat(newData[0]);
        setToggleCat(newData[0].slug);
      }
    }
  }, [match.params.subcat, activeCat]);

  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState([]);
  const addSearchParams = ({ label, value, title }) => {
    const filteredData = searchParams.filter((item, index) => {
      return item.label === label;
    });
    if (filteredData.length > 0) {
      const newArray = searchParams.map((item) => {
        if (item.label === label) {
          item.label = label;
          item.value = value;
          item.title = title;
        }
        return item;
      });
      setSearchParams(newArray);
    } else {
      setSearchParams([...searchParams, { label, value, title }]);
    }
  };
  const removeSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label !== label);
    setSearchParams(filteredData);
  };

  const getSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label === label);
    if (filteredData.length > 0) {
      return filteredData[0].value;
    } else {
      return "";
    }
  };
  const changePage = (page) => {
    setPage(page);
  };
  useEffect(() => {
    addSearchParams({ label: "category_slug", value: match.params.cat });
    addSearchParams({ label: "subcategory_slug", value: match.params.subcat });
  }, [match.params]);

  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      getProducts(page, searchParams);
    }
  }, [page, searchParams]);
  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      countProduct(searchParams);
    }
  }, [searchParams]);

  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />

        <div className="all-product-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="product-top-dt">
                  <div className="product-left-title"></div>
                </div>
              </div>
            </div>
            <div
              className="product-list-view"
              style={{
                background: "#fff",
                padding: "10px 10px",
                boxShadow: "0 2px 2px 0 rgb(0 0 0 / 22%)",
              }}
            >
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <div
                    className="single-product-sidebar"
                    style={{ background: "#fff" }}
                  >
                    {window.innerWidth > 745 ? (
                      <div>
                        <h2>{activeCat && activeCat.name}</h2>
                        {activeCat &&
                          activeCat.subcategories &&
                          activeCat.subcategories.map((item) => {
                            return (
                              <ul>
                                <li>
                                  <div className="categorys-list">
                                    {toggleCat === item.slug ? (
                                      <span onClick={() => setToggleCat(null)}>
                                        {" "}
                                        -{" "}
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() => setToggleCat(item.slug)}
                                      >
                                        +
                                      </span>
                                    )}

                                    <Link
                                      to={`/category/${match.params.cat}/${item.slug}`}
                                    >
                                      {item.name}
                                    </Link>
                                  </div>
                                  <ul
                                    style={{
                                      display:
                                        toggleCat === item.slug
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {item.subcategory &&
                                      item.subcategory.map((cat) => {
                                        return (
                                          <li>
                                            <Link
                                              to={`/category/${match.params.cat}/${item.slug}/${cat.slug}`}
                                            >
                                              {" "}
                                              {cat.name}{" "}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </li>
                              </ul>
                            );
                          })}
                      </div>
                    ) : (
                      <div>
                        <div>
                          {" "}
                          <h2>{activeCat && activeCat.name}</h2>{" "}
                        </div>
                        <div className="mobile-cat-list">
                          <div className="mobile-categorys-list">
                            {activeSubCat &&
                              activeSubCat.subcategory &&
                              activeSubCat.subcategory.map((item) => {
                                return (
                                  <Link
                                    to={`/category/${match.params.cat}/${match.params.subcat}/${item.slug}`}
                                  >
                                    {item.name}
                                  </Link>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-9 col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="breadcrumb-1">
                        <ul style={{ display: "flex" }}>
                          <li>
                            <Link to="/"> Home </Link>
                          </li>
                          <li>
                            {" "}
                            <i className="fa fa-angle-right"></i>{" "}
                          </li>
                          <li>
                            <Link
                              to={`/category/${activeCat && activeCat.slug}`}
                            >
                              {" "}
                              {activeCat && activeCat.name}{" "}
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <i className="fa fa-angle-right"></i>{" "}
                          </li>
                          <li> {activeSubCat && activeSubCat.name} </li>
                        </ul>
                      </div>
                      <div
                        style={{
                          color: "#333",
                          display: " inline-block",
                          verticalAlign: "middle",
                          width: "auto",
                          fontSize: "16px",
                          padding: "5px 0 15px",
                          margin: 0,
                        }}
                      >
                        {activeSubCat && activeSubCat.name}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {!loading ? (
                      products &&
                      products.map((item) => {
                        return (
                          <div className="col-lg-3 col-md-6 col-6">
                            {window.innerWidth > 745 ? (
                              <ProductCard item={item} />
                            ) : (
                              <PorductCardMobile item={item} />
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-md-12">
                        <div>
                          <img
                            src="/images/preloader.gif"
                            style={{ height: "50px", width: "auto" }}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-md-12">
                      {!loading && total_products && (
                        <Pagination
                          total={total_products}
                          current_page={page}
                          limit={50}
                          changePage={(page) => changePage(page)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <FooterMenu />
    </div>
  );
};

const mapStateToProps = (state) => ({
  category: state.category,
  product: state.product,
});

const mapDispatchToProps = { getProducts, countProduct };

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryArchive);
