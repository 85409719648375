import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { getProducts, countProduct } from "../actions/products";
import { URI } from "../constants/constants";
import {
  getSearchs,
  countSearch,
  addSearch,
  resetSearch,
} from "../actions/searches";

import Pagination from "../components/Pagination";
import FooterMenu from "../components/layout/FooterMenu";
import SearchBar from "../components/template/SearchBar";
import ProductCard from "../components/ProductCard";

import PorductCardMobile from "../components/PorductCardMobile";
const Search = ({
  match,
  getSearchs,
  countSearch,
  addSearch,
  resetSearch,

  search: { searches, search, loading, total_searchs },
  category: { category },
}) => {
  const [activeCat, setActiveCat] = useState(null);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState([]);
  const addSearchParams = ({ label, value, title }) => {
    const filteredData = searchParams.filter((item, index) => {
      return item.label === label;
    });
    if (filteredData.length > 0) {
      const newArray = searchParams.map((item) => {
        if (item.label === label) {
          item.label = label;
          item.value = value;
          item.title = title;
        }
        return item;
      });
      setSearchParams(newArray);
    } else {
      setSearchParams([...searchParams, { label, value, title }]);
    }
  };
  const removeSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label !== label);
    setSearchParams(filteredData);
  };

  const getSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label === label);
    if (filteredData.length > 0) {
      return filteredData[0].value;
    } else {
      return "";
    }
  };
  const changePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (search && search.length > 2) {
      addSearchParams({ label: "_q", value: search, title: "Search Term" });
    }
  }, [search]);

  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      getSearchs(page, searchParams);
    }
  }, [page, searchParams]);
  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      countSearch(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    return () => {
      addSearch("");
      resetSearch();
    };
  }, []);

  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />
        <SearchBar />
        <div className="all-product-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="product-top-dt">
                  <div className="product-left-title"></div>
                </div>
              </div>
            </div>
            <div
              className="product-list-view"
              style={{
                background: "#fff",
                padding: "10px 10px",
                boxShadow: "0 2px 2px 0 rgb(0 0 0 / 22%)",
              }}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div style={{ display: "flex" }}>
                    {searchParams &&
                      searchParams.length > 0 &&
                      searchParams.map((item) => {
                        return (
                          <div
                            style={{
                              background: "#fff",
                              padding: "10px 10px",
                              marginRight: "10px",
                              borderRadius: "10px",
                            }}
                          >
                            {item.title} : {item.value}{" "}
                            <button
                              onClick={() => removeSearchParams(item.label)}
                              className="btn btn-sm btn-danger"
                            >
                              X
                            </button>
                          </div>
                        );
                      })}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="breadcrumb-1">
                        <ul style={{ display: "flex" }}>
                          <li>
                            <Link to="/"> Home </Link>
                          </li>
                        </ul>
                      </div>
                      <div
                        style={{
                          color: "#333",
                          display: " inline-block",
                          verticalAlign: "middle",
                          width: "auto",
                          fontSize: "16px",
                          padding: "5px 0 15px",
                          margin: 0,
                        }}
                      >
                        {activeCat && activeCat.name}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {!loading ? (
                      searches &&
                      searches.map((item) => {
                        return (
                          <div className="col-lg-3 col-md-6 col-6">
                            {window.innerWidth > 745 ? (
                              <ProductCard item={item} />
                            ) : (
                              <PorductCardMobile item={item} />
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-md-12">
                        <div>
                          <img
                            src="/images/preloader.gif"
                            style={{ height: "50px", width: "auto" }}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-md-12">
                      {!loading && total_searchs && (
                        <Pagination
                          total={total_searchs}
                          current_page={page}
                          limit={10}
                          changePage={(page) => changePage(page)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {window.innerWidth < 745 && <FooterMenu />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  category: state.category,
  product: state.product,
  search: state.search,
});

const mapDispatchToProps = { getSearchs, countSearch, addSearch, resetSearch };

export default connect(mapStateToProps, mapDispatchToProps)(Search);
