import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSearchs, countSearch, addSearch } from "../../actions/searches";
import { URI } from "../../constants/constants";
import { useHistory } from "react-router-dom";
const SearchBar = ({
  match,
  getSearchs,
  addSearch,
  search: { searches, search },
}) => {
  let history = useHistory();

  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState([]);
  const addSearchParams = ({ label, value, title }) => {
    const filteredData = searchParams.filter((item, index) => {
      return item.label === label;
    });
    if (filteredData.length > 0) {
      const newArray = searchParams.map((item) => {
        if (item.label === label) {
          item.label = label;
          item.value = value;
          item.title = title;
        }
        return item;
      });
      setSearchParams(newArray);
    } else {
      setSearchParams([...searchParams, { label, value, title }]);
    }
  };
  const removeSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label !== label);
    setSearchParams(filteredData);
  };

  const getSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label === label);
    if (filteredData.length > 0) {
      return filteredData[0].value;
    } else {
      return "";
    }
  };
  const changePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (search && search.length > 2) {
      addSearchParams({ label: "_q", value: search });
    }
  }, [search]);

  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      getSearchs(page, searchParams);
    }
  }, [page, searchParams]);
  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      countSearch(searchParams);
    }
  }, [searchParams]);

  return (
    <div className="search120">
      <div className="ui search">
        <div className="ui left icon input swdh10">
          <input
            className="prompt srch10"
            type="text"
            value={search}
            onChange={(e) => addSearch(e.target.value)}
            placeholder="Search for products.."
            onFocus={() => history.push("/search")}
          />
          <i className="uil uil-search-alt icon icon1" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ search: state.search });

const mapDispatchToProps = { getSearchs, addSearch };

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
