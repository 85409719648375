import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";

const Offers = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "60px",
    slidesToScroll: 1,
  };
  return (
    <div>
      <div className="main-banner-slider">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings}>
                {/* <div className="owl-carousel offers-banner owl-theme"> */}
                <div className="item">
                  <div className="offer-item">
                    <div className="offer-item-img">
                      <div className="Savers Delight-overlay" />
                      <img
                        src="https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=50,w=954,h=477/layout-engine/2021-03/Webp.net-resizeimage-2021-03-08T102626.576-1.jpg"
                        alt
                      />
                    </div>
                    <div className="offer-text-dt">
                      <div className="offer-top-text-banner">
                        <p>6% Off</p>
                        <div className="top-text-1">
                          Buy More &amp; Save More
                        </div>
                        <span>Fresh Vegetables</span>
                      </div>
                      <a href="#" className="Offer-shop-btn hover-btn">
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="offer-item">
                    <div className="offer-item-img">
                      <div className="Savers Delight-overlay" />
                      <img
                        src="https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=50,w=954,h=477/layout-engine/2021-03/Webp.net-resizeimage-2021-03-08T102626.576-1.jpg"
                        alt
                      />
                    </div>
                    <div className="offer-text-dt">
                      <div className="offer-top-text-banner">
                        <p>6% Off</p>
                        <div className="top-text-1">
                          Buy More &amp; Save More
                        </div>
                        <span>Fresh Vegetables</span>
                      </div>
                      <a href="#" className="Offer-shop-btn hover-btn">
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>

                {/* </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
