import api from "../utils/api";
import { GET_CATEGORY, CATEGORYS_ERROR } from "./types";

// Get posts
export const getCategory = () => async (dispatch) => {
  try {
    const res = await api.get("/category");

    dispatch({
      type: GET_CATEGORY,
      payload: res.data.main_categories,
    });
  } catch (err) {
    dispatch({
      type: CATEGORYS_ERROR,
      payload: err,
    });
  }
};
