import React from "react";
import { connect } from "react-redux";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

export const CancellationPolicy = ({ match }) => {
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />
        <div className="all-product-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div style={{ background: "#fff", padding: "20px 10px" }}>
                  <h2 className="text-center"> Cancellation Policy </h2>
                  <p>
                    {" "}
                    <strong> Cancellation by Site/Customer </strong>{" "}
                  </p>
                  <p>
                    {" "}
                    You as a customer can cancel your order anytime up to the
                    cut-off time of the slot for which you have placed an order
                    by calling our customer service. In such a case we will
                    refund any payments already made by you for the order. If we
                    suspect any fraudulent transaction by any customer or any
                    transaction which defies the terms & conditions of using the
                    website, we at our sole discretion could cancel such orders.
                    We will maintain a negative list of all fraudulent
                    transactions and customers and would deny access to them or
                    cancel any orders placed by them.{" "}
                  </p>
                  <p>
                    {" "}
                    <strong> Return & Refunds </strong>{" "}
                  </p>
                  <p>
                    {" "}
                    We have a "no questions asked return and refund policy"
                    which entitles all our members to return the product at the
                    time of delivery if due to some reason they are not
                    satisfied with the quality or freshness of the product. We
                    will take the returned product back with us and issue a
                    credit note for the value of the return products which will
                    be credited to your account on the Site. This can be used to
                    pay your subsequent shopping bills.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CancellationPolicy);
