import React from "react";
import { connect } from "react-redux";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

const PrivacyPolicy = ({ match }) => {
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />
        <div className="all-product-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div style={{ background: "#fff", padding: "20px 10px" }}>
                  <h2 className="text-center"> Privacy Policy </h2>
                  <p>
                    This Privacy Policy governs the manner in which SAVERS
                    DELIGHT and its subsidiaries, partners, agents and
                    affiliates collect, use, maintain and disclose information
                    collected from users of the SAVERS DELIGHT.
                  </p>
                  <p>
                    <strong> Personal identification information: </strong> We
                    may collect personal identification information from Users
                    in a variety of ways, including, but not limited to, when
                  </p>
                  <p>
                    {" "}
                    • Users visit our site, subscribe to the newsletter, fill
                    out a form, and in connection with other activities,
                    services, features or resources we make available on our
                    Site.{" "}
                  </p>
                  <p>
                    {" "}
                    • Users may be asked for, as appropriate, name, email
                    address, mailing address, phone number.{" "}
                  </p>
                  <p> • Users may, however, visit our Site anonymously. </p>
                  <p>
                    {" "}
                    • Users can always refuse to supply personal identification
                    information, except that it may prevent them from engaging
                    in certain Site related activities.
                  </p>

                  <p>
                    <strong> Non-personal identification information:</strong>{" "}
                    We may collect non-personal identification information about
                    Users whenever they interact with our Site.{" "}
                  </p>
                  <p>
                    • Non-personal identification information may include the
                    browser name, the type of computer and technical information
                    about Users means the type of connection to our Site, such
                    as the operating system and the Internet service providers
                    utilized and other similar information.
                  </p>
                  <p>
                    <strong> Web browser cookies: </strong> Our Site may use
                    "cookies" to enhance User experience.{" "}
                  </p>
                  <p>
                    • User's web browser places cookies on their hard drive for
                    record-keeping purposes and sometimes to track information
                    about them.{" "}
                  </p>
                  <p>
                    • User may choose to set their web browser to refuse
                    cookies, or to alert the Users when cookies are being sent.
                    If they do so, note that some parts of the Site may not
                    function properly.
                  </p>
                  <p>
                    <strong> How we use collected information: </strong> SAVERS
                    DELIGHT may collect and use User's personal information for
                    the following purposes:
                  </p>
                  <p>
                    • <strong> To improve customer service: </strong>{" "}
                    Information provided by Users helps us respond to the
                    customer service requests and support needs, more
                    efficiently.
                  </p>
                  <p>
                    • <strong> To personalize User experience: </strong> We may
                    use information in the aggregate to understand how our Users
                    as a group use the services and resources provided on our
                    Site.
                  </p>
                  <p>
                    • <strong> To improve our Site: </strong> We may use
                    feedback provided by the User/s to improve our products and
                    services.
                  </p>
                  <p>
                    • To run a promotion, contest, survey or other Site feature.
                  </p>
                  <p>
                    • To send the User/s information they agreed to receive
                    about topics of interest to them.
                  </p>
                  <p>• To send periodic emails.</p>
                  <p>
                    We may use the email address to respond to the inquiries,
                    questions, and/or other requests of User/s. If User/s
                    decide/s to opt to be part of our mailing list, then the
                    User/s will receive emails about company news, updates,
                    related product or service information, etc. If at any time
                    the User/s would like to unsubscribe from receiving future
                    emails, they may do so by contacting us via our Site.
                  </p>
                  <p>
                    <strong> How we protect User's information: </strong> We
                    adopt appropriate data collection, storage and processing
                    practices and security measures to protect against
                    unauthorized access, alteration, disclosure or destruction
                    of User's personal information and data stored on our Site.{" "}
                  </p>
                  <p>
                    Sensitive and private data exchange between the Site and its
                    Users happens over a SSL secured communication channel and
                    is encrypted and protected with digital signatures.
                  </p>
                  <p>
                    <strong> Sharing personal information of User/s: </strong>{" "}
                    We do not sell, trade, or rent User's personal
                    identification information to others. We may share generic
                    aggregated demographic information not linked to any
                    personal identification information regarding User/s with
                    our subsidiaries, our business partners, trusted affiliates
                    and advertisers for the purposes outlined above.
                  </p>
                  <p>
                    <strong> Changes to this privacy policy:</strong> SAVERS
                    DELIGHT shall update this privacy policy at its sole
                    discretion, the date of updation would reflect on this page.
                    Users are advised to check this page for any changes in the
                    privacy policy and to stay informed about how the personal
                    information of the Users is protected by us.{" "}
                  </p>
                  <p>
                    • The User/s hereby acknowledge/s and agree/s that it is
                    their responsibility to review this privacy policy
                    periodically and become aware of modifications.
                  </p>
                  <p>
                    <strong> Your acceptance of these terms: </strong> By using
                    this Site, the Users signify their acceptance of this policy
                    as may be modified from time to time. The Users are advised
                    not to access this site if they do not agree to our privacy
                    policy. The above mentioned privacy policy shall be
                    applicable for the information and data collected by our
                    call centres as well.
                  </p>
                  <p>
                    <strong> Contacting us: </strong> If you have any questions
                    about this Privacy Policy, the practices of this site, or
                    your dealings with this site, please contact us at: SAVERS
                    DELIGHT
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
