import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

const OrderPlaced = ({ match }) => {
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory></ShopByCategory>

        <div className="all-product-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div style={{ background: "#fff" }}>
                  <div className="text-center">
                    <i
                      className="fa fa-check-circle"
                      style={{
                        padding: "20px 0px",
                        fontSize: "36px",
                        color: "green",
                      }}
                    />
                    <div className="heading_s1">
                      <h3>Your order is completed!</h3>
                    </div>
                    <p>
                      Thank you for your order. You will receive an email with
                      order details on your email shortly. Please note
                      Unconfirmed Cash on Delivery orders will not be accepted.
                      To Comfirm COD orders please send YES on whatsapp Helpline
                      +91 93341 41502 before 11AM
                    </p>
                    <div
                      className="link-box clearfix"
                      style={{ padding: "30px 0px" }}
                    >
                      <Link to="/" className="collapsed chck-btn hover-btn">
                        Continue Shopping
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPlaced);
