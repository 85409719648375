import React from "react";
import { connect } from "react-redux";
import ShopByCategory from "../components/homepage/ShopByCategory";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

export const Terms = ({ match }) => {
  return (
    <div>
      <Header props={match} />
      <div className="wrapper">
        <ShopByCategory />
        <div className="all-product-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div style={{ background: "#fff", padding: "20px 10px" }}>
                  <h2 className="text-center"> Terms & Conditions </h2>

                  <p>
                    SAVERS DELIGHT is the licensed owner of the brand
                    saversdelights.com and the website saversdelights.com (”The
                    Site”). As a visitor to the Site/ Customer you are advised
                    to please read the Term & Conditions carefully. By accessing
                    the services provided by the Site you agree to the terms
                    provided in this Terms & Conditions document.
                  </p>
                  <p>
                    {" "}
                    <strong> Personal Information </strong>{" "}
                  </p>
                  <p>
                    As part of the registration process on the Site, SAVERS
                    DELIGHT may collect the following personally identifiable
                    information about you:
                  </p>
                  <p>• Name including first and last name,</p>
                  <p>• alternate email address, </p>
                  <p>• mobile phone number and contact details, </p>
                  <p>• Postal code,</p>
                  <p>
                    • Demographic profile (like your age, gender, occupation,
                    education, address etc.) and{" "}
                  </p>
                  <p>
                    • information about the pages on the site you visit/access,{" "}
                  </p>
                  <p>
                    • the links you click on the site, the number of times you
                    access the page and any such browsing information.
                  </p>
                  <p>Eligibility </p>
                  <p>
                    Services of the Site would be available to only select
                    geographies in India. Persons who are "incompetent to
                    contract" within the meaning of the Indian Contract Act,
                    1872 including un-discharged insolvents etc. are not
                    eligible to use the Site.{" "}
                  </p>
                  <p>
                    • If you are a minor i.e. under the age of 18 years but at
                    least 13 years of age you may use the Site only under the
                    supervision of a parent or legal guardian who agrees to be
                    bound by these Terms of Use.{" "}
                  </p>
                  <p>
                    • If your age is below 18 years your parents or legal
                    guardians can transact on behalf of you if they are
                    registered users. You are prohibited from purchasing any
                    material which is for adult consumption and the sale of
                    which to minors is prohibited.{" "}
                  </p>
                  <p>License & Site access</p>
                  <p>
                    SAVERS DELIGHT grants you a limited sub-license to access
                    and make personal use of this site and not to download
                    (other than page caching) or modify it, or any portion of
                    it, except with express written consent of SAVERS DELIGHT.
                  </p>
                  <p>
                    • This license does not include any resale or commercial use
                    of this site or its contents; any collection and use of any
                    product listings, descriptions, or prices; any derivative
                    use of this site or its contents; any downloading or copying
                    of account information for the benefit of another merchant;
                    or any use of data mining, robots, or similar data gathering
                    and extraction tools.{" "}
                  </p>
                  <p>
                    • This site or any portion of this site may not be
                    reproduced, duplicated, copied, sold, resold, visited, or
                    otherwise exploited for any commercial purpose without
                    express written consent of SAVERS DELIGHT.{" "}
                  </p>
                  <p>
                    • You may not frame or utilize framing techniques to enclose
                    any trademark, logo, or other proprietary information
                    (including images, text, page layout, or form) of the Site
                    or of SAVERS DELIGHT and its affiliates without express
                    written consent.{" "}
                  </p>
                  <p>
                    • You may not use any meta tags or any other "hidden text"
                    utilizing the Site’s or SAVERS DELIGHT’s name or trademarks
                    without the express written consent of SAVERS DELIGHT.{" "}
                  </p>
                  <p>
                    • Any unauthorized use terminates the permission or license
                    granted by SAVERS DELIGHT.
                  </p>
                  <p>Account & Registration Obligations</p>
                  <p>
                    All shoppers have to register and login for placing orders
                    on the Site. You have to keep your account and registration
                    details current and correct for communications related to
                    your purchases from the site. By agreeing to the terms and
                    conditions, the shopper agrees to receive promotional
                    communication and newsletters upon registration. The
                    customer can opt out either by unsubscribing in "My Account"
                    or by contacting the customer service.
                  </p>
                  <p>Pricing</p>
                  <p>
                    All the products listed on the Site will be sold at MRP
                    unless otherwise specified. The prices mentioned at the time
                    of ordering will be the prices charged on the date of the
                    delivery. Although prices of most of the products do not
                    fluctuate on a daily basis but some of the commodities and
                    fresh food prices do change on a daily basis. In case the
                    prices are higher or lower on the date of delivery not
                    additional charges will be collected or refunded as the case
                    may be at the time of the delivery of the order.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
