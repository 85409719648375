import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { URI } from "../constants/constants";
import CartBtn from "./cart-button-mobile/CartBtn";

const ProductCard = ({ item }) => {
  return (
    <div className="col-12">
      <div className="product">
        <div className="product-image">
          <img
            src={
              item.pics && item.pics.length > 0
                ? `${URI}${item.pics[0]}`
                : "https://saversdelights.com/images/product-placeholder.jpg"
            }
          />
        </div>
        <div className="price">
          ₹{item.sale_price}{" "}
          {item.regular_price > item.sale_price && (
            <span> ₹{item.regular_price} </span>
          )}
        </div>
        <div className="product-title">{item.name}</div>
        <div className="unit">{item.units ? item.units : ""}</div>
        <div className="add-to-cart-btn">
          <div>
            <div>
              <CartBtn item={item} />
            </div>
            {/* <div className="qty-cart">
                                    <div className="quantity buttons_added">
                                      <input
                                        type="button"
                                        defaultValue="-"
                                        className="minus minus-btn"
                                      />
                                      <input
                                        type="number"
                                        step={1}
                                        name="quantity"
                                        defaultValue={1}
                                        className="input-text qty text"
                                      />
                                      <input
                                        type="button"
                                        defaultValue="+"
                                        className="plus plus-btn"
                                      />
                                    </div>
                                  </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
