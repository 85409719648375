import React, { useEffect, useState } from "react";

function Pagination({ total, current_page, limit, changePage }) {
  const [total_pages, setTotal_pages] = useState();
  const [pagesArray, setpagesArray] = useState();
  useEffect(() => {
    setTotal_pages(Math.ceil(total / limit));
  }, [total, limit]);
  useEffect(() => {
    setpagesArray([...Array(total_pages).keys()]);
  }, [total_pages]);

  return (
    <div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center mb-0">
          <li
            className={current_page === 1 ? `page-item disabled` : `page-item`}
          >
            <button
              className="page-link"
              href="#"
              tabIndex={-1}
              onClick={() => changePage(current_page - 1)}
            >
              Previous
            </button>
          </li>
          {total_pages &&
            pagesArray.map((item, index) => {
              const remain = current_page - index;
              if (remain < 6 || remain < 3) {
                return (
                  <li
                    className={
                      current_page === index + 1
                        ? `page-item active`
                        : `page-item`
                    }
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => changePage(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                );
              }
            })}

          <li
            className={
              current_page === total_pages ? `page-item disabled` : `page-item`
            }
          >
            <button
              className="page-link"
              onClick={() => changePage(current_page + 1)}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
