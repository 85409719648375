import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CartModal from "./CartModal";
import CategoryModal from "./CategoryModal";
import SearchModal from "./SearchModal";
import { getCategory } from "../../actions/categories";
import { Link } from "react-router-dom";
import { getCart } from "../../actions/carts";
import SearchBar from "./SearchBar";
import { useHistory } from "react-router-dom";
import HeaderMobile from "./HeaderMobile";

const Header = ({ props, getCategory, getCart, cart: { cart } }) => {
  let history = useHistory();
  const [showCart, setShowCart] = useState(false);
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState(false);
  const [deliveryPIN, setDeliveryPIN] = useState(localStorage.getItem("PIN"));
  const [showPopup, setShowPopup] = useState(true);
  useEffect(() => {
    getCategory();
    getCart();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);
  const checkPinCode = () => {
    if (pincode === "800014" || pincode === "800025") {
      localStorage.setItem("PIN", pincode);
      setShowPopup(false);
    } else {
      setPincodeError(true);
    }
  };
  useEffect(() => {}, []);

  return (
    <div>
      <>
        <CategoryModal />
        <SearchModal />
        {window.innerWidth > 745 && (
          <CartModal
            showCart={showCart}
            hideCart={() => setShowCart(!showCart)}
          />
        )}

        {window.innerWidth > 745 ? (
          <div>
            <header className="header clearfix ">
              <div className="top-header-group ">
                <div className="top-header">
                  <div className="res_main_logo">
                    <Link to="/">
                      <img src="/images/logo.png" alt="" />
                    </Link>
                    {/* <a href="index.html">Savers Delight</a> */}
                  </div>
                  <div className="main_logo" id="logo">
                    <Link to="/">
                      <img src="/images/logo.png" alt="" />
                    </Link>
                    <Link to="/">
                      <img class="logo-inverse" src="/images/logo.png" alt="" />
                    </Link>
                    {/* <a href="index.html"> Savers Delight </a> */}
                  </div>
                  <SearchBar />
                  <div className="header_right">
                    <ul>
                      <li>
                        <a
                          href="https://wa.me/919334141502"
                          target="_blank"
                          className="offer-link"
                          style={{ color: "green" }}
                        >
                          <i className="uil uil-whatsapp" />
                          Chat on Whatsapp
                        </a>
                      </li>

                      <li>
                        <Link to="/track-order" className="offer-link">
                          <i className="fa fa-user" />
                          Track Order
                        </Link>
                      </li>

                      <li>
                        <a
                          className="option_links"
                          title="Cart"
                          style={{
                            borderRadius: "5px",
                            fontSize: "20px",
                          }}
                          onClick={() => setShowCart(true)}
                        >
                          <i
                            className="uil uil-heart uil-shopping-cart-alt"
                            style={{ fontSize: "25px" }}
                          />
                          <span className="noti_count1">
                            {" "}
                            {cart && cart.length}{" "}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </header>
          </div>
        ) : (
          <HeaderMobile />
        )}
        {showPopup && !deliveryPIN && (
          <div className="fixed-banner">
            <div className="white-background">
              <h3> Enter Your Pincode </h3>
              <div>
                <label> Pincode </label>
                <select
                  type="number"
                  className="form-control"
                  value={pincode}
                  onChange={(e) => {
                    setPincode(e.target.value);
                    setPincodeError(false);
                  }}
                >
                  <option value=""> </option>
                  <option value="800014"> 800014 </option>
                  <option value="800025"> 800025 </option>
                </select>
              </div>
              {pincodeError && (
                <p className="text-danger">
                  {" "}
                  Pincode not available for delivery{" "}
                </p>
              )}
              <div>
                <button
                  className="btn btn-success"
                  onClick={() => checkPinCode()}
                >
                  Check Pincode
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  category: state.category,
  cart: state.cart,
});

const mapDispatchToProps = { getCategory, getCart };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
