import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
const CategoryOffers = (props) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    centerPadding: "60px",
    slidesToScroll: 1,
  };
  return (
    <div>
      <div className="section145">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-title-tt">
                <div className="main-title-left">
                  <span>For You</span>
                  <h2>Fresh Vegetables &amp; Fruits</h2>
                </div>
                <a href="#" className="see-more-btn">
                  See All
                </a>
              </div>
            </div>
            <div className="col-md-12">
              <Slider {...settings}>
                {/* <div className="owl-carousel featured-slider owl-theme"> */}
                <div className="item">
                  <div className="product-item">
                    <a href="single_product_view.html" className="product-img">
                      <img src="images/product/img-11.jpg" alt />
                      <div className="product-absolute-options">
                        <span className="offer-badge-1">6% off</span>
                        <span className="like-icon" title="wishlist" />
                      </div>
                    </a>
                    <div className="product-text-dt">
                      <p>
                        Available<span>(In Stock)</span>
                      </p>
                      <h4>Product Title Here</h4>
                      <div className="product-price">
                        $12 <span>$15</span>
                      </div>
                      <div className="qty-cart">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                          />
                          <input
                            type="number"
                            step={1}
                            name="quantity"
                            defaultValue={1}
                            className="input-text qty text"
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            className="plus plus-btn"
                          />
                        </div>
                        <span className="cart-icon">
                          <i className="uil uil-shopping-cart-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="product-item">
                    <a href="single_product_view.html" className="product-img">
                      <img src="images/product/img-12.jpg" alt />
                      <div className="product-absolute-options">
                        <span className="offer-badge-1">2% off</span>
                        <span className="like-icon" title="wishlist" />
                      </div>
                    </a>
                    <div className="product-text-dt">
                      <p>
                        Available<span>(In Stock)</span>
                      </p>
                      <h4>Product Title Here</h4>
                      <div className="product-price">
                        $10 <span>$13</span>
                      </div>
                      <div className="qty-cart">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                          />
                          <input
                            type="number"
                            step={1}
                            name="quantity"
                            defaultValue={1}
                            className="input-text qty text"
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            className="plus plus-btn"
                          />
                        </div>
                        <span className="cart-icon">
                          <i className="uil uil-shopping-cart-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="product-item">
                    <a href="single_product_view.html" className="product-img">
                      <img src="images/product/img-13.jpg" alt />
                      <div className="product-absolute-options">
                        <span className="offer-badge-1">5% off</span>
                        <span className="like-icon" title="wishlist" />
                      </div>
                    </a>
                    <div className="product-text-dt">
                      <p>
                        Available<span>(In Stock)</span>
                      </p>
                      <h4>Product Title Here</h4>
                      <div className="product-price">
                        $5 <span>$8</span>
                      </div>
                      <div className="qty-cart">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                          />
                          <input
                            type="number"
                            step={1}
                            name="quantity"
                            defaultValue={1}
                            className="input-text qty text"
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            className="plus plus-btn"
                          />
                        </div>
                        <span className="cart-icon">
                          <i className="uil uil-shopping-cart-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="product-item">
                    <a href="single_product_view.html" className="product-img">
                      <img src="images/product/img-1.jpg" alt />
                      <div className="product-absolute-options">
                        <span className="offer-badge-1">3% off</span>
                        <span className="like-icon" title="wishlist" />
                      </div>
                    </a>
                    <div className="product-text-dt">
                      <p>
                        Available<span>(In Stock)</span>
                      </p>
                      <h4>Product Title Here</h4>
                      <div className="product-price">
                        $15 <span>$20</span>
                      </div>
                      <div className="qty-cart">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                          />
                          <input
                            type="number"
                            step={1}
                            name="quantity"
                            defaultValue={1}
                            className="input-text qty text"
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            className="plus plus-btn"
                          />
                        </div>
                        <span className="cart-icon">
                          <i className="uil uil-shopping-cart-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="product-item">
                    <a href="single_product_view.html" className="product-img">
                      <img src="images/product/img-5.jpg" alt />
                      <div className="product-absolute-options">
                        <span className="offer-badge-1">2% off</span>
                        <span className="like-icon" title="wishlist" />
                      </div>
                    </a>
                    <div className="product-text-dt">
                      <p>
                        Available<span>(In Stock)</span>
                      </p>
                      <h4>Product Title Here</h4>
                      <div className="product-price">
                        $9 <span>$10</span>
                      </div>
                      <div className="qty-cart">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                          />
                          <input
                            type="number"
                            step={1}
                            name="quantity"
                            defaultValue={1}
                            className="input-text qty text"
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            className="plus plus-btn"
                          />
                        </div>
                        <span className="cart-icon">
                          <i className="uil uil-shopping-cart-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="product-item">
                    <a href="single_product_view.html" className="product-img">
                      <img src="images/product/img-6.jpg" alt />
                      <div className="product-absolute-options">
                        <span className="offer-badge-1">2% off</span>
                        <span className="like-icon" title="wishlist" />
                      </div>
                    </a>
                    <div className="product-text-dt">
                      <p>
                        Available<span>(In Stock)</span>
                      </p>
                      <h4>Product Title Here</h4>
                      <div className="product-price">
                        $7 <span>$8</span>
                      </div>
                      <div className="qty-cart">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                          />
                          <input
                            type="number"
                            step={1}
                            name="quantity"
                            defaultValue={1}
                            className="input-text qty text"
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            className="plus plus-btn"
                          />
                        </div>
                        <span className="cart-icon">
                          <i className="uil uil-shopping-cart-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="product-item">
                    <a href="single_product_view.html" className="product-img">
                      <img src="images/product/img-14.jpg" alt />
                      <div className="product-absolute-options">
                        <span className="offer-badge-1">1% off</span>
                        <span className="like-icon" title="wishlist" />
                      </div>
                    </a>
                    <div className="product-text-dt">
                      <p>
                        Available<span>(In Stock)</span>
                      </p>
                      <h4>Product Title Here</h4>
                      <div className="product-price">
                        $6.95 <span>$7</span>
                      </div>
                      <div className="qty-cart">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                          />
                          <input
                            type="number"
                            step={1}
                            name="quantity"
                            defaultValue={1}
                            className="input-text qty text"
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            className="plus plus-btn"
                          />
                        </div>
                        <span className="cart-icon">
                          <i className="uil uil-shopping-cart-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="product-item">
                    <a href="single_product_view.html" className="product-img">
                      <img src="images/product/img-3.jpg" alt />
                      <div className="product-absolute-options">
                        <span className="offer-badge-1">3% off</span>
                        <span className="like-icon" title="wishlist" />
                      </div>
                    </a>
                    <div className="product-text-dt">
                      <p>
                        Available<span>(In Stock)</span>
                      </p>
                      <h4>Product Title Here</h4>
                      <div className="product-price">
                        $8 <span>$10</span>
                      </div>
                      <div className="qty-cart">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                          />
                          <input
                            type="number"
                            step={1}
                            name="quantity"
                            defaultValue={1}
                            className="input-text qty text"
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            className="plus plus-btn"
                          />
                        </div>
                        <span className="cart-icon">
                          <i className="uil uil-shopping-cart-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryOffers);
