import api from "../utils/api";
import {
  GET_PRODUCTS,
  TOTAL_PRODUCTS,
  ADD_PRODUCT,
  GET_PRODUCT,
  PRODUCTS_ERROR,
  RESET_PRODUCT,
} from "./types";

// Get posts
export const countProduct = (searchParams) => async (dispatch) => {
  try {
    let query = "/products/count";
    let newQ = ``;
    searchParams.map((item, index) => {
      if (index === 0) {
        newQ += `?${item.label}=${item.value}`;
      } else {
        newQ += `&${item.label}=${item.value}`;
      }
    });
    console.log("Query", newQ);
    query = query + newQ;
    let res = await api.get(query);
    dispatch({
      type: TOTAL_PRODUCTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload: err,
    });
  }
};

// Get posts
export const getProducts = (page, searchParams) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PRODUCT,
    });
    let start = 0;
    let limit = 50;
    if (page) {
      start = (page - 1) * 50;
    }
    let query =
      "/products?_limit=" +
      limit +
      "&_start=" +
      start +
      "&_sort=createdAt:DESC";
    if (searchParams) {
      let newQ = ``;
      searchParams.map((item) => {
        newQ += `&${item.label}=${item.value}`;
      });
      console.log("Query", newQ);
      query = query + newQ;
    }

    const res = await api.get(query);

    dispatch({
      type: GET_PRODUCTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload: err,
    });
  }
};
// Get posts
export const getProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PRODUCT,
    });
    const res = await api.get("/products/" + id);

    dispatch({
      type: GET_PRODUCT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload: err,
    });
  }
};

export const addProduct = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/products`, data);

    dispatch({
      type: ADD_PRODUCT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload: err,
    });
  }
};
export const editProduct = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/products/" + id, data);

    dispatch(getProduct(id));
  } catch (err) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload: err,
    });
  }
};
